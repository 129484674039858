.main-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.section-1 {
  width: 100%;
  display: flex;
  row-gap: 1rem;
  column-gap: 1rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.section-2 {
  width: 100%;
  display: flex;
  row-gap: 1rem;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

.websites-container {
  display: flex;
  row-gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.empty-space {
  margin-top: 2rem;
}

.experience-container {
  display: flex;
  justify-content: center;
}

.w-50 {
  width: 50%;
}

.w-full {
  width: 100%;
}