@keyframes roll-in {
	0% {
		opacity: 0;
		transform: translateY(-250px) rotate(-200deg);
	}

	100% {
		opacity: 1;
		transform: translateY(0) rotate(0deg);
	}
}

@keyframes slide-from-left {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slide-from-right {
	0% {
		opacity: 0;
		transform: translateX(250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slide-in-bottom {
	0% {
		opacity: 0;
		transform: translateY(250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


@keyframes slide-out-top {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-250px);
	}
}