.project-card {
    transition: transform ease 300ms;
}
  
.project-card:hover {
    /* transform: translate(0, -10px); */
    transform: scale(1.1);
}


.project-card-title {
    line-height: 2.5rem;
}

.project-card {
    width: 28rem;
    height: 17rem;
}

@media (max-width: 480px) {
 .project-card {
    width: 25rem;
 }   
 .project-card:hover {
    transform: none;
 }
}