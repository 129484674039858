.projects-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 1rem;
}

.websites-title {
    font-size: 4rem;
    margin: 2rem;
}