.title-component-container {
    width: 50%;
}

.title-component-title {
    font-size: 10rem;
    line-height: 10rem;
    animation: slide-from-left 2s ease 0s 1 normal forwards;
}

.title-component-sub-title {
    font-size: 3rem;
    animation: slide-from-right 2s ease 0s 1 normal forwards;
}

.avatar-container {
    animation: slide-from-left 2s ease 0s 1 normal forwards;
}

.avatar-image {
    width: 28rem;
}