.nav-container {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 40px;
    height: 40px;
}

.connect-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 1rem;
}

.connect-button {
    min-height: 45px;
    border-color: transparent;
}