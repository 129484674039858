.basic-info-card {
    animation: slide-in-bottom 2s ease 0s 1 normal forwards;
    width: 28rem;
}

.bio-info {
    font-size: 1.25rem;
}

.connect-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 1rem;
}

.connect-button {
    min-height: 45px;
}

.card-title {
    font-size: 2.5rem;
}

.spacer {
    height: 30%;
}

.list-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.list-section {
    width: 20%;
}

@media (max-width: 480px) {
    .basic-info-card {
       width: 25rem;
    }   
}